<template>
  <div class="w-full h-full pt-5">
    <div class="flex mb-10 px-5">
      <h1 class="text-xl text-left font-extrabold mr-4">
        Pay Run
      </h1>
      <breadcrumb :items="breadcrumbs" />
    </div>
    <div class="px-3">
      <card class="p-5 mt-3">
        <div class="mx-2 mt-6">
          <Tab
            :tabs="tabs"
            border
            :active-tab="currentTab"
            @currentTab="currentTab = $event"
            v-model="currentTab"
          />
          <div>
            <div>
              <Card class="mt-6 p-5">
                <div
                  :class="
                    !loading && annualGrossTotal !== 100
                      ? 'flex justify-between'
                      : ''
                  "
                  v-if="currentTab === 'Drafts'"
                >
                  <alert
                    :message="` ${
                      100 - annualGrossTotal
                    }% of your gross earnings are
                      unallocated. Add more pay items to get to 100%.`"
                    variant="primary"
                    v-if="!loading && Number(annualGrossTotal) !== 100"
                    :time="2"
                    style="
                      position: relative;
                      right: 0;
                      top: -12px;
                      border: 1px solid #e99323;
                      box-shadow: none;
                      background: rgba(244, 213, 176, 0.15);
                      color: #e99323;
                    "
                  />
                  <div class="flex mb-6 gap-3" style="justify-content: flex-end">
                    <Button
                      width="11rem"
                      :disabled="annualGrossTotal !== 100"
                      class="text-white bg-dynamicBackBtn"
                      style-name="width: 176px"
                      options
                      :option-list="['Regular Payrun', 'Back Pay']"
                      :background="appearance.buttonColor || defaultBTNColor"
                      @click="buttonOptionSelected($event)"
                    >
                      Regular Pay
                    </Button>

                    <Button
                      width="11rem"
                      :disabled="annualGrossTotal !== 100"
                      class="text-dynamicBackBtn w- bg-white border border-solid border-dynamicBackBtn"
                      style-name="width: 176px"
                      options
                      :option-list="offCycles"
                      @click="offCyclesOptionSelected($event)"
                    >
                      Off-Cycle Pay 
                    </Button>
                  </div>
                </div>
                <template v-slot:footer>
                  <card-footer v-if="!showError" :show-bulk-actions="false" />
                </template>
              </Card>
            </div>
            <div class="pt-3" v-if="!showError">
              <sTable
                :headers="headers"
                :items="selectedPayrunType"
                class="w-full h-full"
                aria-label="payrun table"
                :loading="loading"
                :has-checkbox="false"
                :has-number="true"
                :pagination-list="metaData"
                @page="handlePage($event)"
                @itemsPerPage="handleItemsPerPage($event)"
                page-sync
                v-if="loading || selectedPayrunType.length"
              >
                <template v-slot:item="{ item }">
                  <span v-if="item.year" style="width: 189px">
                    {{
                      `${$DATEFORMAT(
                        new Date(item.data.year, item.data.month - 1, 1),
                        "LLLL"
                      )} ${item.data.year}`
                    }}
                  </span>
                  <div v-else-if="item.payFrequency">
                    {{ handlePayFrequency(item.data.payFrequency) }}
                  </div>
                  <div class="capitalize flex flex-col justify-start gap-1" v-else-if="item.payType">
                    <span class="text-sm font-semibold">
                      {{ item.data.type === "off-cycle" ? item.data.paymentType : item.data.payType }}
                    </span>
                    <span class="uppercase text-romanSilver text-10 font-bold">  
                      {{ item.data.type }}
                    </span>
                  </div>
                  <div class="" v-else-if="item.paySplitPosition">
                    {{ `${$getOrdinal(item.data.paySplitPosition)} Payment` }}
                  </div>

                  <span v-else-if="item.paidDate">
                    {{ item.data.paidDate }}
                  </span>
                  <span v-else-if="item.paidAt">
                    {{
                      item.data.paidAt
                        ? $DATEFORMAT(new Date(item.data.paidAt), "yyyy-MM-dd")
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.createdAt">
                    {{
                      item.data.createdAt
                        ? $DATEFORMAT(
                            new Date(item.data.createdAt),
                            "yyyy-MM-dd"
                          )
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.paymentReferrenceId">
                    {{ item.data.paymentReferrenceId }}
                  </span>
                  <span v-else-if="item.bankName">
                    {{ item.data.bankName ? toTitleCase(item.data.bankName) : '---' }}
                  </span>
                  <span v-else-if="item.accountNumber">
                    {{ item.data.accountNumber }}
                  </span>
                  <span v-else-if="item.status">
                    {{ item.data.status }}
                  </span>

                  <span v-else-if="item.type">
                    {{ item.data.type ? toTitleCase(item.data.type) : '---'}}
                  </span>
                  <span v-else-if="item.payDate">
                    {{ item.data.payDate ? item.data.payDate : "-" }}
                  </span>
                  <span v-else-if="item.grossEarnings">
                    {{
                      item.data.grossEarnings
                        ? convertToCurrency(item.data.grossEarnings)
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.netEarnings">
                    {{
                      item.data.netEarnings
                        ? convertToCurrency(item.data.netEarnings)
                        : "-"
                    }}
                  </span>
                  <span v-else-if="item.payrollStatus" 
                    class="grosspay capitalize font-semibold" 
                    :style="{
                        backgroundColor: item.data.payrollStatus === 'draft' ? `#878E9926` : 
                          item.data.payrollStatus === 'review' ? `#E9932326` : 
                          item.data.payrollStatus === 'approved' ? `#13B56A26` :
                          item.data.payrollStatus === 'disapproved' ? `#EA3C5326` : `#13B56A26`,
                        color: item.data.payrollStatus === 'draft' ? `#878E99` : 
                          item.data.payrollStatus === 'review' ? `#E99323` : 
                          item.data.payrollStatus === 'approved' ? `#13B56A` :
                          item.data.payrollStatus === 'disapproved' ? `#EA3C53` : `#13B56A`,
                        width: item.data.payrollStatus === 'review' && `148px`,
                        display: item.data.payrollStatus === 'review' && `inline-block`
                      }"
                  >
                      {{ item.data.payrollStatus && item.data.payrollStatus === 'review' ? 'Pending approval' : item.data.payrollStatus }}
                  </span>
                  <span v-else-if="item.id">
                    <div
                      class="cursor-pointer"
                      v-if="!item.data.editable && currentTab !== 'Approved'"
                      @click="viewDetails(item.data)"
                    >
                      <icon
                        icon-name="icon-eye"
                        class-name="text-blueCrayola"
                        size="xs"
                      />
                    </div>
                    <Menu
                      v-if="!item.data.editable && currentTab === 'Approved'"
                      left
                      top="-150"
                      margin="24"
                      class="my-2 p-0"
                    >
                      <template v-slot:title>
                        <icon icon-name="more_icon" size="xs" />
                      </template>
                      <div style="width: 116px; height: 81px" class="py-3 px-2">
                        <div
                          @click="viewDetails(item.data)"
                          class="flex py-1 px-3 more h-8 cursor-pointer"
                        >
                          <icon
                            icon-name="icon-eye"
                            class-name="text-blueCrayola mr-2"
                            size="xsm"
                          />
                          <p class="pt-1">View</p>
                        </div>
                        <div
                          @click="showCancelComponent(item.data.id)"
                          class="flex py-1 px-3 more h-8 cursor-pointer"
                        >
                          <icon
                            icon-name="close-icon"
                            class-name="text-desire mr-2"
                            size="xsm"
                          />
                          <p class="pt-1">Cancel</p>
                        </div>
                      </div>
                    </Menu>
                  </span>
                </template>
              </sTable>

              <div v-else class="flex flex-col justify-center items-center gap-0 px-10 pb-32">
                <c-icon icon-name="paygrade_Illustration" size="" class-name="w-56 h-56" />
                <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
                  There is no payrun available. Click on "Regular Pay" or "Off-Cycle Pay" 
                  option to continue
                </p>
              </div>
            </div>
            <div v-else>
              <error-page />
            </div>
            <div class="pt-3">
              <Paid v-show="false" />
            </div>
          </div>
        </div>
      </card>
    </div>
    <new-payrun-filter :open-modal="openModal" @close="openModal = false" />
    <cancel-modal
      :show-cancel-modal="showCancelModal"
      @cancelModal="handleCancelModal"
      @canceled="handleCanceled"
      :pay-run-data-id="payRunDataId"
    />
    <new-back-pay :open-modal="openBackPayModal" @close="openBackPayModal = false" />
    <new-off-cycles-filter 
      :open-off-cycles-modal="openOffCyclesModal" 
      :selected-off-cycle="selectedOffCycle"
      :payYear="payYear"
      @close="openOffCyclesModal = false" 
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Menu from "@/components/Menu";
import Button from "@/components/Button";
import CardFooter from "@/components/CardFooter";
import ErrorPage from "@/modules/Admin/error403";
import CIcon from "@/components/Icon";
import Paid from "./Paid";
import NewPayrunFilter from "./NewPayrunFilter";
import CancelModal from "./CancelModal";
import NewBackPay from "./NewBackPay";
import NewOffCyclesFilter from "./offcycles/NewOffCyclesFilter";
import { capitalize } from "lodash";

export default {
  name: "Payrun",
  components: {
    Tab,
    Breadcrumb,
    Card,
    STable,
    Button,
    Menu,
    CardFooter,
    CancelModal,
    NewBackPay,
    ErrorPage,
    Paid,
    Alert,
    CIcon,
    NewPayrunFilter,
    NewOffCyclesFilter,
  },
  data() {
    return {
      offCycles: ['None'],
      offCyclesOptions: [],
      payYear: "",
      openOffCyclesModal: false,
      selectedOffCycle: {},
      currentTab: this.$route.query.currentTab || "Drafts",
      showError: false,
      annualGrossTotal: 100,
      showCancelModal: false,
      payRunDataId: "",
      environment: process.env.NODE_ENV,
      openModal: false,
      openBackPayModal: false,
      metaData: {},
      tabs: ["Drafts", "Under Review", "Approved", "Disapproved", "Paid"],
      currentText: "Created At",
      payruns: {},
      loading: true,
      loadingBackPay: false,
      selectedPayrunType: [],
      status: "",
      state: 'drafts',
      optionSelected: "Back to Regular Payrun",
      payrunItems: [],
      breadcrumbs: [
        { disabled: false, text: "Payroll", href: "Payroll", id: "Payroll" },
        { disabled: false, text: "Pay run", href: "Payrun", id: "Payrun" },
      ],
      itemsPerPage: null,
      numberOfPage: null,
    };
  },

  watch: {
    currentTab(value) {
      this.checkCurrentTab(value);
      if (value === "Drafts") {
        this.getAnnualGrossTotal();
      }
      this.$router.push({ query: { currentTab: value } });
    },
  },
  computed: {
    headers() {
      const headerArry = [
        { title: "Pay Cycle", value: "year" },
        { title: "Payrun Type", value: "payType" },
        { title: "Pay Frequency", value: "payFrequency" },
        { title: "Pay Schedule", value: "paySplitPosition" },
        {
          title: this.currentTab === "Paid" ? "Pay Date" : "Pay Date",
          value: this.currentTab === "Paid" ? "paidAt" : "createdAt",
        },
        { title: "Gross Pay", value: "grossEarnings" },
        { title: "Net Pay", value: "netEarnings" },
        { title: "Status", value: "payrollStatus" },
        { title: "", value: "id", image: true },
      ];
      return headerArry;
    },
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";

      if (this.currentTab === "Drafts") {
        this.retrievePayrun("draft", `${pageNumber}${itemPage}`);
      } else if (this.currentTab === "Under Review") {
        this.retrievePayrun("review", `${pageNumber}${itemPage}`);
      } else if (this.currentTab === "Approved") {
        this.retrievePayrun("approved", `${pageNumber}${itemPage}`);
      } else if (this.currentTab === "Paid") {
        this.currentText = "Pay Date";
        this.retrievePayrun("paid", `${pageNumber}${itemPage}`);
      } else if (this.currentTab === "Disapproved") {
        this.retrievePayrun("disapproved", `${pageNumber}${itemPage}`);
      }
    },
    async showCancelComponent(id) {
      try {
        await this.$handlePrivilege("payrun", "cancelApprovedPayRun");
        this.payRunDataId = id;
        this.showCancelModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    handleCancelModal() {
      this.showCancelModal = false;
      this.payRunDataId = "";
    },
    handleCanceled() {
      this.showCancelModal = false;
      this.retrievePayrunByType("approved");
    },
    checkCurrentTab(value) {
      switch (value) {
        case "Drafts":
            this.getPayrun("draft");
          break;

        case "Under Review":
            this.getPayrun("review");
          break;

        case "Approved":
            this.getPayrun("approved");
          break;

        case "Paid":
          this.currentText = "Pay Date";
            this.getPayrun("paid");
          break;

        case "Disapproved":
            this.getPayrun("disapproved");
          break;

        default:
          break;
      }
    },

    buttonOptionSelected(option) {
      this.optionSelected = option;
      switch (option) {
        case "Regular Payrun":
          this.newPayRun();
          break;

          case "Back Pay":
          this.backPayPayrun();
          break;

        default:
          break;
      }
    },

    offCyclesOptionSelected(option) {
      if(option === 'None'){
        this.openOffCyclesModal = false;
      }else{
        const filteredOption = this.offCyclesOptions.filter((offCycle, index) => offCycle.nameOnPayslip === option)
      
        this.selectedOffCycle = filteredOption[0];
        this.openOffCyclesModal = true;
      }
    },

    getBackPayData(params) {
      this.loadingBackPay = true;
      this.state = params;
      switch (params) {
        case "drafts":
          this.$_getBackPay().then((response) => {
            this.payrunItems = response.data.unprocessedPayruns;
            this.loadingBackPay = false;
          });
          break;

        case "review":
          this.$_getPayrun(params, "&page=1&perPage=50").then((result) => {
            this.payrunItems = result.data.payruns;
            this.metaData = result.data.meta;
            this.loadingBackPay = false;
          });
          break;

        default:
          break;
      }
    },

    getToday() {
      return String(new Date().getDate()).padStart(2, "0");
    },
    handlePayFrequency(value) {
      switch (value) {
        case "twice_monthly":
          return "Twice Monthly";
        case "monthly":
          return "Monthly";
        case "daily":
          return "Daily";
        case "weekly":
          return "Four Times Monthly";
        default:
          return "";
      }
    },

    async viewDetails(value) {
      if (this.currentTab === "Drafts") {
        const draftPayload = {
          month: value.month,
          year: value.year,
          payType: value.payType,
          type: "draft"
        };
        try {
          await this.$handlePrivilege("payRun", "viewPayRunOnDraft");
          this.$router.push({
            name: "Edit Payrun",
            params: { id: value.id },
            query: draftPayload,
          });
        } catch (error) {
          this.$toasted.error(
            "You do not have permission to perform this task",
            {
              duration: 5000,
            }
          );
        }
      } else if (this.currentTab === "Under Review") {
        const slug = "reviewSubmittedPayRunDetails";
        this.routeView(value, slug);
      } else if (this.currentTab === "Approved") {
        const slug = "viewApprovedPayRunDetails";
        this.routeView(value, slug);
      } else if (this.currentTab === "Disapproved") {
        const slug = "viewDisapprovedPayRunDetails";
        this.routeView(value, slug);
      } else if (this.currentTab === "Paid") {
        const slug = "viewPaidPayRunDetails";
        this.routeView(value, slug);
      }
    },

    async routeView(value, slug) {
      try {
        await this.$handlePrivilege("payRun", slug);
        this.$router.push({
          name: "View Payrun",
          params: { id: value.id },
          query: { currentTab: this.currentTab },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    retrievePayrun(value, params) {
      this.showError = false;
      this.loading = true;
      this.$_getPayrun(value, params, 'regular').then((result) => {
        this.selectedPayrunType = result.data.payruns;
        this.metaData = result.data.meta;
        this.loading = false;
      });
    },

    retrievePayrunByType(currentTab, params) {
      this.retrievePayrun(currentTab, params);
    },

    async getPayrun(currentTab) {
      this.loading = true;
      if (this.currentTab === "Drafts") {
        try {
          await this.$handlePrivilege("payRun", "viewDraftRegister");
          this.showError = false;
          this.retrievePayrun(currentTab, "&page=1&perPage=50");
        } catch (error) {
          this.showError = true;
          this.loading = false;
        }
      } else if (this.currentTab === "Under Review") {
        try {
          await this.$handlePrivilege("payRun", "viewUnderReviewRegister");
          this.showError = false;
          this.retrievePayrunByType(currentTab, "&page=1&perPage=50");
        } catch (error) {
          this.showError = true;
          this.loading = false;
        }
      } else if (this.currentTab === "Approved") {
        try {
          await this.$handlePrivilege("payRun", "viewApprovedRegister");
          this.showError = false;
          this.retrievePayrunByType(currentTab, "&page=1&perPage=50");
        } catch (error) {
          this.showError = true;
          this.loading = false;
        }
      } else if (this.currentTab === "Paid") {
        try {
          await this.$handlePrivilege("payRun", "viewPaidRegister");
          this.showError = false;
          this.retrievePayrunByType(currentTab, "&page=1&perPage=50");
        } catch (error) {
          this.showError = true;
          this.loading = false;
        }
      } else if (this.currentTab === "Disapproved") {
        try {
          await this.$handlePrivilege("payRun", "viewDisapprovedRegister");
          this.showError = false;
          this.retrievePayrunByType(currentTab, "&page=1&perPage=50");
        } catch (error) {
          this.showError = true;
          this.loading = false;
        }
      }
    },

    async newPayRun() {
      try {
        await this.$handlePrivilege("payRun", "createNewPayRun");
        this.openModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async backPayPayrun() {
      try {
        await this.$handlePrivilege("payRun", "createNewPayRun");
        this.openBackPayModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    getAnnualGrossTotal() {
      this.$_getOrgGross().then((result) => {
        this.annualGrossTotal = result.data.currentTotalGross || 0;
      });
    },

    async getOffCyclePay() {
      try {
        const { data } = await this.$_getOffCyclePay();
        
        this.offCyclesOptions = data.offCycles;
        this.payYear = data.payYear;
        this.offCycles = data.offCycles.length >= 1 ? data.offCycles.map((offCycle, index) => offCycle.nameOnPayslip) : ['None']
      } catch (error) {
        this.offCycles = ['None']
      }
    },
  },
  mounted() {
    this.checkCurrentTab(this.currentTab);
    if (this.currentTab === "Drafts") {
      this.getAnnualGrossTotal();
    }
  },
  beforeMount() {
    this.getOffCyclePay();
  }
};
</script>

<style scoped>
.custom-radius {
  border-radius: 7px;
}
.grosspay {
  background: #2176ff26;
  padding: 0.3rem 0.7rem;
  color: #2176ff;
  border-radius: 5px;
}

.text-10{
  font-size: 10px !important
}
</style>
